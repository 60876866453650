import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sf-modalContent" }
const _hoisted_2 = { class: "sf-modalFormTitle" }
const _hoisted_3 = { class: "sf-modalFormContainer" }
const _hoisted_4 = { class: "sf-modalFormName" }
const _hoisted_5 = { class: "sf-modalFormCity" }
const _hoisted_6 = { class: "sf-modalFormPhone" }
const _hoisted_7 = { class: "sf-modalFormEmail" }
const _hoisted_8 = { class: "sf-modalFormMessage" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "sf-modalFormInfo" }
const _hoisted_11 = { class: "sf-agreeCheckboxLabel" }
const _hoisted_12 = { class: "sf-modalFormSend" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = {
  key: 1,
  class: "sf-modalForm sf-modalFormSent",
  style: {"text-align":"center"}
}
const _hoisted_15 = {
  key: 2,
  class: "sf-modalPicContainer"
}
const _hoisted_16 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal'))),
      class: "sf-closeModalButton"
    }, " × "),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.contactTitle), 1),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.sent)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.sendModalForm && _ctx.sendModalForm(...args))),
            class: "sf-modalForm"
          }, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
                placeholder: "imię i nazwisko *",
                class: _normalizeClass(["sf-contactInput sf-contactInputName", { 'sf-inputError': _ctx.nameError }]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.nameError = false))
              }, null, 2), [
                [_vModelText, _ctx.name]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.city) = $event)),
                placeholder: "miasto *",
                class: _normalizeClass(["sf-contactInput sf-contactInputCity", { 'sf-inputError': _ctx.cityError }]),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cityError = false))
              }, null, 2), [
                [_vModelText, _ctx.city]
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.phone) = $event)),
                placeholder: "telefon *",
                class: _normalizeClass(["sf-contactInput sf-contactInputPhone", { 'sf-inputError': _ctx.phoneError }]),
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.phoneError = false))
              }, null, 2), [
                [_vModelText, _ctx.phone]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.email) = $event)),
                placeholder: "email *",
                class: _normalizeClass(["sf-contactInput sf-contactInputEmail", { 'sf-inputError': _ctx.emailError }]),
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.emailError = false))
              }, null, 2), [
                [_vModelText, _ctx.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("textarea", {
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.message) = $event)),
                placeholder: "wiadomość",
                class: "sf-contactInput sf-contactInputMsg"
              }, null, 512), [
                [_vModelText, _ctx.message]
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("span", null, "* pola obowiązkowe", -1))
            ]),
            _createElementVNode("div", {
              class: "sf-modalFormPolicy",
              innerHTML: _ctx.contactFormPolicy
            }, null, 8, _hoisted_9),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("label", _hoisted_11, [
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  name: "agree",
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.agree) = $event)),
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.agreeToggle && _ctx.agreeToggle(...args)))
                }, null, 512), [
                  [_vModelCheckbox, _ctx.agree]
                ]),
                _createElementVNode("span", {
                  class: _normalizeClass(["sf-agreeCheckmark", { 'sf-agreeError': _ctx.agreeError }])
                }, null, 2),
                _createTextVNode(" " + _toDisplayString(_ctx.contactFormInfo), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_vue_recaptcha, {
                sitekey: _ctx.siteKey,
                "load-recaptcha-script": true,
                onVerify: _ctx.handleSuccess,
                onError: _ctx.handleError,
                onExpired: _ctx.handleExpired
              }, null, 8, ["sitekey", "onVerify", "onError", "onExpired"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("button", {
                class: _normalizeClass(["sf-modalButtonSend", _ctx.modalButtonSendClass]),
                disabled: _ctx.sendDisabled
              }, _toDisplayString(_ctx.contactFormButton), 11, _hoisted_13)
            ])
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.sent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, _cache[14] || (_cache[14] = [
            _createElementVNode("p", null, [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                height: "4em",
                viewBox: "0 0 640 512",
                class: "sf-modalSentIcon"
              }, [
                _createElementVNode("path", {
                  fill: "currentColor",
                  d: "M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0l57.4-43c23.9-59.8 79.7-103.3 146.3-109.8l13.9-10.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176V384c0 35.3 28.7 64 64 64H360.2C335.1 417.6 320 378.5 320 336c0-5.6 .3-11.1 .8-16.6l-26.4 19.8zM640 336a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zm-76.7-43.3c6.2 6.2 6.2 16.4 0 22.6l-72 72c-6.2 6.2-16.4 6.2-22.6 0l-40-40c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L480 353.4l60.7-60.7c6.2-6.2 16.4-6.2 22.6 0z"
                })
              ])
            ], -1),
            _createElementVNode("p", null, "Dziękujemy za informację", -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.isModalPicture)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("img", {
              src: _ctx.modalPicPath,
              class: "sf-modalPic"
            }, null, 8, _hoisted_16)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}